import { AlbumImage } from './AlbumImage'

export async function fetchRedditAlbum(url: string): Promise<AlbumImage[]> {
  const jsonUrl = url.endsWith('.json') ? url : url + '.json'
  const res = await fetch(jsonUrl).then((res) => res.json())
  console.log(res)

  let post: any = undefined
  for (const item of res) {
    for (const child of item.data.children) {
      if (child.kind === 't3') {
        post = child.data
        break
      }
    }
    if (post) {
      break
    }
  }

  if (post) {
    const images: AlbumImage[] = []
    for (const item of post.gallery_data.items) {
      const media = post.media_metadata[item.media_id]
      images.push({
        id: item.media_id,
        src: media.s.u.replace(/\&amp;/g, '&'),
        height: media.s.y,
        width: media.s.x,
      })
    }
    return images
  } else {
    throw new Error('No post found')
  }
}
