import { fetchImgurAlbum } from './useImgurAlbum'
import { fetchRedditAlbum } from './useRedditAlbum'
import { AlbumImage } from './AlbumImage'
import { useState, useEffect } from 'react'

interface LoadingState<T> {
  loading?: boolean
  error?: string
  value?: T
}
export const useAlbum = (
  url: string,
): [AlbumImage[] | undefined, boolean | undefined, string | undefined] => {
  const [state, setState] = useState<LoadingState<AlbumImage[]>>({})

  useEffect(() => {
    let promise: Promise<AlbumImage[]>
    if (url.includes('reddit.com')) {
      promise = fetchRedditAlbum(url)
    } else if (url.includes('imgur.com') || url) {
      promise = fetchImgurAlbum(url)
    }
    if (promise) {
      setState({ loading: true })
      promise
        .then((res) => {
          console.log(res)
          setState({ loading: false, value: res })
        })
        .catch((err) =>
          setState({
            loading: false,
            value: undefined,
            error: err?.message ?? err,
          }),
        )
    }
  }, [url])

  return [state.value, state.loading, state.error]
}
