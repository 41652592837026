import { AlbumImage } from './AlbumImage'

export async function fetchImgurAlbum(url: string): Promise<AlbumImage[]> {
  let albumHash = url
  const match = url.match(/https?:\/\/(?:i\.)?imgur\.com\/(?:a\/)(.*)/)

  if (match) {
    albumHash = match[1]
  }

  const res = await fetch(`https://api.imgur.com/3/album/${albumHash}/images`, {
    headers: { Authorization: 'Client-ID b70c7461b551c54' },
  }).then((res) => res.json())

  console.log(res)

  if (res.success) {
    if (res && res.data) {
      return res.data.map((i: any) => {
        return { id: i.id, src: i.link, height: i.height, width: i.width }
      })
    } else {
      throw new Error('No data')
    }
  } else {
    throw new Error(res.data.error)
  }
}
