import React from 'react'

import * as styles from './Images.module.scss'
import { useAlbum } from './useAlbum'

export const Images: React.FC<{
  url: string
}> = ({ url }) => {
  const [images, loading, error] = useAlbum(url)
  console.log(images, loading, error)
  if (loading) {
    return <p>Loading</p>
  }
  if (error) {
    return <p>{error}</p>
  }
  if (!images) {
    return null
  }
  return (
    <div className={styles.album}>
      {images.map((img) => (
        <img
          key={img.id}
          src={img.src}
          height={img.height}
          width={img.width}
          alt=""
          className={styles.image}
          loading="lazy"
        />
      ))}
    </div>
  )
}
