import React, { useState } from 'react'

import * as styles from './App.module.scss'
import { Images } from './Images'

export const App: React.FC = () => {
  const [hash, setHash] = useState('')

  return (
    <div className={styles.App}>
      <label>
        Enter album hash
        <input value={hash} onChange={(e) => setHash(e.target.value)} />
      </label>
      <Images url={hash} />
    </div>
  )
}
